<template>
  <section class="patterns">
    <div class="container">
      <div class="heading-wrap">
        <h2 id="patterns">{{ $t('patterns.title') }}</h2>
        <button class="btn btn-btn d-sm-none" @click="downloadFile">{{ $t('patterns.btn') }}</button>
      </div>
      <div class="desc" v-html="$t('patterns.desc')"></div>
      <div class="items">
        <div class="item">
          <picture>
            <source media="(min-width:1680px)" srcset="@/img/patterns-blue-1920.svg">
            <source media="(min-width:1200px)" srcset="@/img/patterns-blue-1440.svg">
            <source media="(min-width:1020px)" srcset="@/img/patterns-blue-1024.svg">
            <source media="(min-width:768px)" srcset="@/img/patterns-blue-768.svg">
            <img src="@/img/patterns-blue-414.svg" alt="" class="img">
          </picture>
        </div>
        <div class="item">
          <picture>
            <source media="(min-width:1680px)" srcset="@/img/patterns-green-1920.svg">
            <source media="(min-width:1200px)" srcset="@/img/patterns-green-1440.svg">
            <source media="(min-width:1020px)" srcset="@/img/patterns-green-1024.svg">
            <source media="(min-width:768px)" srcset="@/img/patterns-green-768.svg">
            <img src="@/img/patterns-green-414.svg" alt="" class="img">
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_pattern.zip";
      link.download = "LILBET_pattern.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.patterns {
  .items {
    padding-top: 40px;

    @media (min-width: 1020px) {
      padding-top: 60px;
    }

    >:last-child {
      position: relative;
      top: -1px;
    }
  }

  .img {
    display: block;
    width: 100%;
  }
}
</style>