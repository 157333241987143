// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')

import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";

// Подключаем global SCSS файл
import "./styles/main.scss";

// Импортируем переводы
import en from "./locales/en.json";
import ru from "./locales/ru.json";

// Настраиваем i18n
const i18n = createI18n({
  locale: "en", // Язык по умолчанию
  fallbackLocale: "en", // Язык по умолчанию при отсутствии перевода
  messages: {
    en,
    ru,
  },
  globalInjection: true, // Разрешает использовать $t в любом месте проекта
  warnHtmlMessage: false, // Отключает предупреждения о HTML-тегах
});

const app = createApp(App);
app.use(i18n); // Подключаем i18n к приложению
app.mount("#app");

// menu smooth-scroll start
document.querySelectorAll('.menu-link').forEach(link => {
	link.addEventListener('click', function (e) {
		e.preventDefault(); // Отменяем стандартное поведение ссылки

		const targetId = this.getAttribute('href').substring(1); // Получаем ID цели
		const targetElement = document.getElementById(targetId); // Находим элемент по ID

		if (targetElement) {
			// Рассчитываем точное положение с учетом отступа
			const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 100; // Отступ 100px

			// Прокручиваем страницу к рассчитанной позиции
			window.scrollTo({
				top: targetPosition,
				behavior: 'smooth' // Плавная прокрутка
			});
		} 
	});
});
// menu smooth-scroll end
