<template>
  <section class="layout-construction">
    <div class="container">
      <h2>{{ $t('layout-construction.title') }}</h2>
      <div class="desc" v-html="$t('layout-construction.desc')"></div>
      <div class="content">
        <div class="img-wrap-m"><img src="@/img/layout-construction-image-m.webp" alt="" class="img"></div>
        <div class="img-wrap-d"><img src="@/img/layout-construction-image-d.webp" alt="" class="img"></div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.layout-construction {
  .content {
    margin-top: -2px;
    margin-bottom: -6px;

    @media (min-width: 768px) {
      padding-top: 40px;
      margin-top: 0;
    }

    @media (min-width: 1200px) {
      padding-top: 60px;
    }
  }

  .img-wrap-m {
    display: block;
    padding-top: 24px;
    text-align: center;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .img-wrap-d {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    padding-left: 6px;
  }
  ol li {
    counter-increment: my-awesome-counter;
    line-height: 128%;
    display: flex;
    gap: 6px;
  }
  ol li::before {
    content: counter(my-awesome-counter) ". ";
  }
}
</style>