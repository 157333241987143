<template>
  <section class="layout">
    <div class="container">
      <div class="heading-wrap">
        <h2>{{ $t('layout.title') }}</h2>
        <div class="custom-navigation">
          <button @click="slidePrev" :disabled="isBeginning" class="custom-prev"
            :class="{ disabled: isBeginning }"></button>
          <button @click="slideNext" :disabled="isEnd" class="custom-next" :class="{ disabled: isEnd }"></button>
        </div>
      </div>

    </div>
    <swiper :pagination="{
      clickable: true,
    }" @swiper="onSwiperInit" @slideChange="updateNavigationState" :slidesPerView="'auto'" :spaceBetween="0"
      :modules="modules" :breakpoints="breakpoints" >
      <swiper-slide>
        <div>
          <div class="swiper-desc-wrapper">
            <div class="swiper-desc" v-html="$t('layout.desc1')"></div>
          </div>
          <div class="img-wrap-m">
            <img src="@/img/layout-image-01-m.webp" alt="" class="img" />
          </div>
          <div class="img-wrap-d">
            <img src="@/img/layout-image-01-d.webp" alt="" class="img" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <div class="swiper-desc-wrapper">
            <div class="swiper-desc" v-html="$t('layout.desc2')"></div>
          </div>
          <div class="img-wrap-m">
            <img src="@/img/layout-image-02-m.webp" alt="" class="img" />
          </div>
          <div class="img-wrap-d">
            <img src="@/img/layout-image-02-d.webp" alt="" class="img" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <div class="swiper-desc-wrapper">
            <div class="swiper-desc" v-html="$t('layout.desc3')"></div>
          </div>
          <div class="img-wrap-m">
            <img src="@/img/layout-image-03-m.webp" alt="" class="img" />
          </div>
          <div class="img-wrap-d">
            <img src="@/img/layout-image-03-d.webp" alt="" class="img" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <div class="swiper-desc-wrapper">
            <div class="swiper-desc" v-html="$t('layout.desc4')"></div>
          </div>
          <div class="img-wrap-m">
            <img src="@/img/layout-image-04-m.webp" alt="" class="img" />
          </div>
          <div class="img-wrap-d">
            <img src="@/img/layout-image-04-d.webp" alt="" class="img" />
          </div>
        </div>
      </swiper-slide>
    </swiper>

  </section>
</template>

<script>

import { ref } from 'vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

// import './style.css';

// import required modules
import { Pagination } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiperInstance = ref(null);
    const isBeginning = ref(true);
    const isEnd = ref(false);

    const onSwiperInit = (swiper) => {
      swiperInstance.value = swiper;
      updateNavigationState(); // Убедимся, что состояние кнопок обновлено сразу
    };

    const slideNext = () => {
      if (swiperInstance.value) {
        swiperInstance.value.slideNext();
      }
    };

    const slidePrev = () => {
      if (swiperInstance.value) {
        swiperInstance.value.slidePrev();
      }
    };

    const updateNavigationState = () => {
      if (swiperInstance.value) {
        isBeginning.value = swiperInstance.value.isBeginning;
        isEnd.value = swiperInstance.value.isEnd;
      }
    };

    const breakpoints = {
      768: {
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: true
      },
      1020: {
        centeredSlides: true,
        spaceBetween: 32,
        slidesPerView: 'auto',
        loop: true
      },
    };

    return {
      modules: [Pagination],
      onSwiperInit,
      slideNext,
      slidePrev,
      updateNavigationState,
      breakpoints,
      isBeginning,
      isEnd,
    };
  },
};
</script>

<style lang="scss">
.layout {
  .swiper {
    padding-top: 24px;
    margin-bottom: -8px;

    .swiper-desc {
      padding-bottom: 24px;
      width: calc(100dvw - 32px);

      @media (min-width: 768px) {
        width: unset;
      }

      @media (min-width: 1020px) {
        padding-bottom: 40px;
      }

      @media (min-width: 1680px) {
      }
    }

    .swiper-wrapper {
      align-items: stretch
    }

    .swiper-wrapper .swiper-slide:last-child .swiper-desc {
      right: 16px;
      position: absolute;

      @media (min-width: 768px) {
        position: unset;
      }
    }

    .swiper-wrapper .swiper-slide {
      padding-left: 16px;

      @media (min-width: 768px) {
        padding: unset;
      }
    }

    .swiper-wrapper .swiper-slide:last-child {
      padding-right: 16px;

      @media (min-width: 768px) {
        padding: unset;
      }
    }

    .swiper-slide {
      width: 85dvw;
      height: auto;
      opacity: .2;

      @media (min-width: 768px) {
        width: calc(100vw - 32px);
      }

      @media (min-width: 768px) {
        width: 688px;
      }

      @media (min-width: 1020px) {
        width: 930px;
      }

      @media (min-width: 1200px) {
        width: 1120px;
      }

      @media (min-width: 1680px) {
        width: 1328px;
      }

      >div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }

    .swiper-button-prev {
      background-color: seagreen;
    }

    .swiper-button-next {
      background-color: seagreen;
    }

    .swiper-pagination {
      position: relative;
      display: flex;
      justify-content: center;
      gap: 8px;
      padding-top: 28px;
      width: auto !important;

      @media (min-width: 768px) {
        display: none;
      }

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        margin: 4px;
        transform: scale(0.75);
      }

      .swiper-pagination-bullet-active {
        background-color: var(--blue);
        transform: scale(1);
      }

      .swiper-pagination-bullet-active-next {
        transform: scale(0.75);
      }

      .swiper-pagination-bullet-active-next-next {
        transform: scale(0.75);
      }
    }
  }

  .img-wrap-m {
    display: block;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .img-wrap-d {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
  img {
    width: 100%;
  }
}

.custom-navigation {
  display: none;
  justify-content: space-between;
  gap: 16px;
}

@media (min-width: 768px) {
  .custom-navigation {
    display: flex;
  }
}

.custom-prev,
.custom-next {
  width: 32px;
  height: 32px;
  background-color: var(--green);
  border: none;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1200px) {
    width: 52px;
    height: 52px;
    border-radius: 14px;
  }
}

.custom-prev::before {
  content: '';
  display: inline-block;
  background: url('../img/icon-chevron-prev-m.svg') center no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 2px;

  @media (min-width: 1200px) {
    background: url('../img/icon-chevron-prev-d.svg') center no-repeat;
    width: 32px;
    height: 32px;
  }
}

.custom-next::before {
  content: '';
  display: inline-block;
  background: url('../img/icon-chevron-next-m.svg') center no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 2px;

  @media (min-width: 1200px) {
    background: url('../img/icon-chevron-next-d.svg') center no-repeat;
    width: 32px;
    height: 32px;
  }
}

.custom-prev.disabled,
.custom-next.disabled {
  background-color: var(--no-active-black);
  cursor: not-allowed;
}

.custom-prev.disabled::before {
  background: url('../img/icon-chevron-prev-disabled-m.svg') center no-repeat;

  @media (min-width: 1200px) {
    background: url('../img/icon-chevron-prev-disabled-d.svg') center no-repeat;
  }
}

.custom-next.disabled::before {
  background: url('../img/icon-chevron-next-disabled-m.svg') center no-repeat;

  @media (min-width: 1200px) {
    background: url('../img/icon-chevron-next-disabled-d.svg') center no-repeat;
  }
}

.custom-prev:hover:not(.disabled),
.custom-next:hover:not(.disabled) {
  background-color: var(--pink);
}

.layout .swiper-slide img {
  border: none;
  border-radius: 8px;

  @media (min-width: 1200px) {
    border-radius: 16px;
  }
}

.layout .swiper-slide .swiper-desc {
  opacity: 0;
}

.layout .swiper-slide-active .swiper-desc {
  opacity: 1;
}

.layout .swiper .swiper-slide-active {
  opacity: 1;
}
</style>