<template>
  <section class="symbol-short-version">
    <div class="container container-btn">
      <div class="heading-wrap">
        <h2>{{ $t('symbol-short-version.title') }}</h2>
        <button class="btn btn-btn" @click="downloadFile">{{ $t('symbol-short-version.btn') }}</button>
      </div>
      <div class="content">
        <div class="desc" v-html="$t('symbol-short-version.desc')"></div>
        <div><img src="@/img/symbol-short-version.svg" alt=""></div>
      </div>
      <div class="items">
        <div class="item item--blue">
          <img src="@/img/symbol-green.svg" alt="">
          <div class="text">
            <div>#082b53</div>
            <div>RGB: 8, 43, 83</div>
          </div>
        </div>
        <div class="item item--green">
          <img src="@/img/symbol-white.svg" alt="">
          <div class="text">
            <div>#082b53</div>
            <div>RGB: 8, 43, 83</div>
          </div>
        </div>
        <div class="item item--white">
          <img src="@/img/symbol-green.svg" alt="">
          <div class="text">
            <div>#082b53</div>
            <div>RGB: 8, 43, 83</div>
          </div>
        </div>
        <div class="item item--black">
          <img src="@/img/symbol-white.svg" alt="">
          <div class="text">
            <div>#082b53</div>
            <div>RGB: 8, 43, 83</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_logo_short.zip";
      link.download = "LILBET_logo_short.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.symbol-short-version {
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
    margin-top: 38px;

    @media(min-width: 768px) {
      flex-direction: unset;
      align-items: unset;
      margin-top: unset;
      gap: 40px;
    }

    @media (min-width: 1020px) {
      gap: 100px;
    }

    @media (min-width: 1680px) {
      gap: 160px;
    }

    img {
      width: 260px;

      @media (min-width: 768px) {
        width: 240px;
        padding: 24px 0;
      }

      @media (min-width: 1020px) {
        width: 300px;
        padding: 48px 38px 16px;
      }
    }
  }

  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 40px 0 16px;

    @media (min-width: 768px) {
      padding: 40px 0;
      grid-template-columns: repeat(4, 1fr);
      border-bottom: 1px solid var(--pink);
    }

    @media (min-width: 1200px) {
      padding: 60px 0;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 26px 20px;

    @media (min-width: 768px) {
      padding: 16px 20px;
    }

    @media (min-width: 1020px) {
      padding: 21px 14px;
    }

    @media (min-width: 1200px) {
      padding: 32px 20px;
    }

    @media (min-width: 1680px) {
      padding: 42px 20px;
    }
  }

  .item--blue {
    background-color: var(--blue);
    color: var(--white);
  }

  .item--green {
    background-color: var(--green);
    color: var(--white);
  }

  .item--white {
    background-color: var(--white);
    color: var(--green);
    border-left: 1px solid var(--blue-light);
    border-bottom: 1px solid var(--blue-light);

    @media (min-width: 768px) {
      border-top: 1px solid var(--blue-light);
      border-right: 1px solid var(--blue-light);
      border-bottom: 1px solid var(--blue-light);
    }
  }

  .item--black {
    background-color: var(--black);
    color: var(--white);
  }

  .text {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 4px;
    font-size: 16px;
    letter-spacing: 0.14px;

    @media (min-width: 768px) {
      gap: 0;
    }

    @media (min-width: 1020px) {
      gap: 24px;
      flex-direction: row;
    }

    @media (min-width: 1200px) {}
  }
}
</style>