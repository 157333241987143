<template>
  <section class="highlighting-panel">
    <div class="container container-btn">
      <div class="heading-wrap">
        <h2 id="layout">{{ $t('highlighting-panel.title') }}</h2>
        <button class="btn btn-btn" @click="downloadFile">{{ $t('highlighting-panel.btn') }}</button>
      </div>
      <div class="content">
        <div class="img-wrap"><img src="@/img/highlighting-panel-image.svg" alt=""></div>
        <div class="desc" v-html="$t('highlighting-panel.desc')"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_element.zip";
      link.download = "LILBET_element.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.highlighting-panel {
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;

    @media (min-width: 768px) {
      flex-direction: unset;
      border-bottom: 1px solid var(--pink);
      padding-bottom: 58px;
      gap: 16px;
    }

    @media (min-width: 1020px) {
      gap: 32px;
      padding-top: 32px;
      padding-bottom: 54px;
    }
  }

  .desc {
    margin: 0;
  }

  img {
    width: 320px;

    @media (min-width: 768px) {
      width: 336px;
    }

    @media (min-width: 1020px) {
      width: 100%;
    }
  }

  .img-wrap {
    text-align: center;
    flex-shrink: 0;
  }
}
</style>