<template>
  <section class="photography-style">
    <div class="container">
      <div class="content">
        <h2>{{ $t('photography-style.title') }}</h2>
        <div class="desc" v-html="$t('photography-style.desc')"></div>
      </div>
      <div class="img-wrap">
        <picture>
          <source media="(min-width:1680px)" srcset="@/img/photography-style-image-1920.webp">
          <source media="(min-width:1200px)" srcset="@/img/photography-style-image-1440.webp">
          <source media="(min-width:1020px)" srcset="@/img/photography-style-image-1024.webp">
          <source media="(min-width:768px)" srcset="@/img/photography-style-image-768.webp">
          <img src="@/img/photography-style-image-414.webp" alt="" class="img">
        </picture>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.photography-style {
  position: relative;
  overflow: hidden;
  background: var(--green);
  padding-top: 40px;
  margin-bottom: 0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding-top: 100px;
  }

  @media (min-width: 1020px) {
    padding-top: 65px;
    padding-bottom: 112px;
    height: 588px;
  }

  @media (min-width: 1200px) {
    padding-top: 74px;
    padding-bottom: unset;
    height: 510px;
  }

  @media (min-width: 1680px) {
    padding-top: 97px;
  }

  .container {
    position: unset;
  }

  .desc {
    @media (min-width: 768px) {
      height: 230px;
    }
    
    @media (min-width: 1020px) {
      height: unset;
    }
  }

  .content {
    @media (min-width: 1020px) {
      width: 375px;
    }

    @media (min-width: 1200px) {
      width: 435px;
    }

    @media (min-width: 1680px) {
      width: 512px;
    }
  }

  .img-wrap {
    margin-top: 16px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1020px) {
      margin-top: 0;
    }

    @media (min-width: 1200px) {}

    @media (min-width: 1680px) {}

    .img {
      position: relative;
      display: block;

      &-768,
      &-1024,
      &-1440,
      &-1920 {
        display: none;
      }

      @media (min-width: 768px) {
        display: block;
        width: 83%;
        left: 44px;

        &-414,
        &-1024,
        &-1440,
        &-1920 {
          display: none;
        }
      }

      @media (min-width: 1020px) {
        display: block;
        position: absolute;
        left: calc(50% - 70px);
        bottom: -1px;
        width: 581px;

        &-414,
        &-768,
        &-1440,
        &-1920 {
          display: none;
        }
      }

      @media (min-width: 1200px) {
        display: block;
        left: calc(50% + 12px);
        bottom: 0;
        width: 643px;

        &-414,
        &-768,
        &-1024,
        &-1920 {
          display: none;
        }
      }

      @media (min-width: 1680px) {
        display: block;
        width: 784px;
        left: calc(50% - 110px);

        &-414,
        &-768,
        &-1024,
        &-1440 {
          display: none;
        }
      }
    }
  }
}
</style>