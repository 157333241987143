<template>
  <section class="banners bg-blue text-white">
    <div class="container">
      <div class="content">
        <h2 id="banners">{{ $t('banners.title') }}</h2>

        <div class="dual-slider" ref="mainSlider">
          <div class="swiper thumb-slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide" @click="goToSlide(0)">
                <span>{{ $t('banners.list.item1') }}</span>
              </div>
              <div class="swiper-slide" @click="goToSlide(1)">
                <span>{{ $t('banners.list.item2') }}</span>
              </div>
              <div class="swiper-slide" @click="goToSlide(2)">
                <span>{{ $t('banners.list.item3') }}</span>
              </div>
              <div class="swiper-slide" @click="goToSlide(3)">
                <span>{{ $t('banners.list.item4') }}</span>
              </div>
              <div class="swiper-slide" @click="goToSlide(4)">
                <span>{{ $t('banners.list.item5') }}</span>
              </div>
              <div class="swiper-slide" @click="goToSlide(5)">
                <span>{{ $t('banners.list.item6') }}</span>
              </div>
              <div class="swiper-slide" @click="goToSlide(6)">
                <span>{{ $t('banners.list.item7') }}</span>
              </div>
            </div>
          </div>

          <div class="swiper main-slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="@/img/banners-image-01.webp" alt="Slide 1" />
              </div>
              <div class="swiper-slide">
                <img src="@/img/banners-image-02.webp" alt="Slide 2" />
              </div>
              <div class="swiper-slide">
                <img src="@/img/banners-image-03.webp" alt="Slide 3" />
              </div>
              <div class="swiper-slide">
                <img src="@/img/banners-image-04.webp" alt="Slide 4" />
              </div>
              <div class="swiper-slide">
                <img src="@/img/banners-image-05.webp" alt="Slide 5" />
              </div>
              <div class="swiper-slide">
                <img src="@/img/banners-image-06.webp" alt="Slide 6" />
              </div>
              <div class="swiper-slide">
                <img src="@/img/banners-image-07.webp" alt="Slide 7" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';
import { Navigation, Thumbs, EffectFade } from 'swiper/modules';
import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Thumbs, EffectFade]);

export default {
  data() {
    return {
      mainSwiper: null,
      thumbSwiper: null,
      resizeListener: null,
      autoplayTimeout: null,
      observer: null,
    };
  },

  mounted() {
    this.initThumbSwiper();

    this.mainSwiper = new Swiper(".main-slider", {
      spaceBetween: 40,
      effect: 'fade',
      fadeEffect: { crossFade: true },
      thumbs: { swiper: this.thumbSwiper },
      loop: true,
    });

    // Добавляем обработчики для сброса автоплея
    this.initMainSwiperHandlers();

    this.initVisibilityObserver();
    this.checkInitialVisibility();

    this.resizeListener = () => this.updateThumbSwiperDirection();
    window.addEventListener("resize", this.resizeListener);
  },

  beforeUnmount() {
    if (this.resizeListener) {
      window.removeEventListener("resize", this.resizeListener);
    }

    if (this.observer) {
      this.observer.disconnect();
    }

    this.clearAutoplay();
  },

  methods: {
    startManualAutoplay() {
      this.clearAutoplay();
      this.autoplayTimeout = setInterval(() => {
        if (this.mainSwiper) {
          this.mainSwiper.slideNext();
        }
      }, 10000);
    },

    clearAutoplay() {
      if (this.autoplayTimeout) {
        clearInterval(this.autoplayTimeout);
        this.autoplayTimeout = null;
      }
    },

    resetAutoplay() {
      this.clearAutoplay();
      this.startManualAutoplay();
    },

    goToSlide(index) {
      if (this.mainSwiper) {
        this.mainSwiper.slideTo(index);
        this.resetAutoplay();
      }
    },

    initThumbSwiper() {
      if (this.thumbSwiper) {
        this.thumbSwiper.destroy(true, true);
      }

      this.thumbSwiper = new Swiper(".thumb-slider", {
        slidesPerView: 'auto',
        direction: window.matchMedia("(min-width: 1020px)").matches ? "vertical" : "horizontal",
        spaceBetween: 8,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: true,
      });

      if (this.mainSwiper) {
        this.mainSwiper.thumbs.swiper = this.thumbSwiper;
        this.mainSwiper.thumbs.update();
      }

      this.thumbSwiper.on('touchEnd', () => {
        setTimeout(() => this.resetAutoplay(), 500);
      });
    },

    updateThumbSwiperDirection() {
      if (this.thumbSwiper) {
        this.thumbSwiper.destroy(true, true);
      }

      this.thumbSwiper = new Swiper(".thumb-slider", {
        slidesPerView: 'auto',
        direction: window.matchMedia("(min-width: 1020px)").matches ? "vertical" : "horizontal",
        spaceBetween: 8,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: true,
      });

      if (this.mainSwiper) {
        this.mainSwiper.thumbs.swiper = this.thumbSwiper;
        this.mainSwiper.thumbs.update();
      }
    },

    initMainSwiperHandlers() {
      // Сбрасываем автоплей при окончании свайпа
      this.mainSwiper.on('touchEnd', () => {
        setTimeout(() => this.resetAutoplay(), 500);
      });

      // Дополнительно сбрасываем при окончании перехода
      this.mainSwiper.on('transitionEnd', () => {
        setTimeout(() => this.resetAutoplay(), 500);
      });
    },

    initVisibilityObserver() {
      const options = { root: null, threshold: 0.1 };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.startManualAutoplay();
          } else {
            this.clearAutoplay();
          }
        });
      }, options);

      const target = this.$refs.mainSlider?.closest(".banners");
      if (target) {
        this.observer.observe(target);
      }
    },

    checkInitialVisibility() {
      const target = this.$refs.mainSlider?.closest(".banners");
      if (target) {
        const rect = target.getBoundingClientRect();
        const isVisible =
          rect.top < window.innerHeight && rect.bottom > 0;

        if (isVisible) {
          this.startManualAutoplay();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banners {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 160px);
  background: linear-gradient(90deg, #253865 0%, #0F2A5A 100%);
  padding: 80px 0;
  margin: 0;

  @media (min-width: 768px) {
    min-height: calc(100vh - 200px);
    padding: 100px 0;
  }

  h2 {
    @media (min-width: 1020px) {
      position: absolute;
    }
  }

  .dual-slider {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin-top: 24px;

    @media (min-width: 768px) {
      gap: 20px;
    }

    @media (min-width: 1020px) {
      justify-content: space-between;
      flex-direction: row;
      margin-top: 0;
    }
  }

  .swiper {
    margin: unset;
  }

  .thumb-slider {
    width: 100%;

    @media (min-width: 1020px) {
      width: auto;
      margin-top: 80px;
    }

    .swiper-slide {
      width: auto;

      @media (min-width: 1020px) {
        height: 45px !important;
      }

      @media (min-width: 1200px) {
        height: 53px !important;
      }
    }

    .swiper-slide span {
      display: inline-block;
      font-family: 'Tektur';
      font-size: 20px;
      letter-spacing: normal;
      color: var(--no-active-blue);
      padding: 8px 8px 8px 0;
      cursor: pointer;
      transition: .3s;

      @media (min-width: 768px) {
        padding: 8px 16px 8px 0;
      }

      @media (min-width: 1020px) {
        font-size: 22px;
        padding: 12px 16px 12px 0;
      }

      @media (min-width: 1200px) {
        font-size: 22px;
      }

      &:hover {
        color: var(--pink);
      }
    }

    .swiper-slide-thumb-active span {
      color: var(--white)
    }
  }

  .main-slider {
    width: 100%;

    @media (min-width: 1020px) {
      width: 533px;
    }

    @media (min-width: 1200px) {
      width: 643px;
    }

    @media (min-width: 1680px) {
      width: 830px;
    }

    .swiper-slide img {
      width: 100%;
    }
  }
}
</style>