<template>
  <section class="fonts section-colored bg-blue text-white">
    <div class="container container-btn">
      <div class="heading-wrap">
        <h2 id="fonts">{{ $t('fonts.title') }}</h2>
        <button class="btn btn-btn" @click="downloadFile">{{ $t('fonts.btn') }}</button>
      </div>
      <div class="desc" v-html="$t('fonts.desc')"></div>
      <div class="items">
        <div class="item">
          <img src="@/img/fonts-tektur.svg" alt="" class="img">
        </div>
        <div class="item item--roboto">
          <img src="@/img/fonts-roboto.svg" alt="" class="img">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_fonts.zip";
      link.download = "LILBET_fonts.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.fonts {
  padding: 80px 0 60px;

  @media (min-width: 768px) {
    padding: 80px 0 88px;
  }

  @media (min-width: 1020px) {
    padding: 80px 0 100px;
  }

  @media (min-width: 1200px) {
    padding: 100px 0;
  }

  .desc {
    @media (min-width: 1020px) {
      height: 69px;
    }
    @media (min-width: 1200px) {
      height: 46px;
    }
  }

  .items {
    display: grid;
    justify-content: center;
    gap: 28px;
    padding-top: 24px;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      justify-content: unset;
      padding-top: 40px;
      gap: 32px;
      margin-bottom: -6px;
    }

    @media (min-width: 1200px) {
      padding-top: 60px;
    }

    .item {
      width: 282px;

      @media (min-width: 768px) {
        width: 371px;
      }

      @media (min-width: 1020px) {
        width: 371px;
      }

      @media (min-width: 1200px) {
        width: unset;
      }
    }

    .item--roboto {
      @media (min-width: 768px) {
        position: absolute;
        right: 18px;
      }

      @media (min-width: 1200px) {
        position: unset;
        right: unset;
      }
    }
  }
}
</style>