<template>

  <header class="header">
    <div class="container">
      <div class="header-content">
        <a href="#" class="logo"><img src="@/img/logo.svg" alt="logo"></a>
        <button class="menu-btn">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </button>
        <div class="menu">
          <nav>
            <ul>
              <li><a href="#logo" class="menu-link">{{ $t('nav.item1') }}</a></li>
              <li><a href="#colors" class="menu-link">{{ $t('nav.item2') }}</a></li>
              <li><a href="#fonts" class="menu-link">{{ $t('nav.item3') }}</a></li>
              <li><a href="#patterns" class="menu-link">{{ $t('nav.item4') }}</a></li>
              <li><a href="#layout" class="menu-link">{{ $t('nav.item5') }}</a></li>
              <li><a href="#graphics" class="menu-link">{{ $t('nav.item6') }}</a></li>
              <li><a href="#banners" class="menu-link">{{ $t('nav.item7') }}</a></li>
            </ul>
          </nav>
          <button class="lang-btn" @click="switchLanguage">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2015_3596)">
                <path
                  d="M9.9974 18.3346C5.3949 18.3346 1.66406 14.6038 1.66406 10.0013C1.66406 5.3988 5.3949 1.66797 9.9974 1.66797C14.5999 1.66797 18.3307 5.3988 18.3307 10.0013C18.3307 14.6038 14.5999 18.3346 9.9974 18.3346ZM8.08906 16.3905C7.26691 14.6466 6.79062 12.7598 6.68656 10.8346H3.3824C3.54455 12.1171 4.07566 13.3248 4.9112 14.3111C5.74674 15.2973 6.85074 16.0197 8.08906 16.3905ZM8.35573 10.8346C8.48156 12.8671 9.0624 14.7763 9.9974 16.4613C10.9577 14.7318 11.5186 12.8092 11.6391 10.8346H8.35573ZM16.6124 10.8346H13.3082C13.2042 12.7598 12.7279 14.6466 11.9057 16.3905C13.1441 16.0197 14.248 15.2973 15.0836 14.3111C15.9191 13.3248 16.4502 12.1171 16.6124 10.8346ZM3.3824 9.16797H6.68656C6.79062 7.24279 7.26691 5.35604 8.08906 3.61214C6.85074 3.98287 5.74674 4.70526 4.9112 5.69155C4.07566 6.67783 3.54455 7.88555 3.3824 9.16797ZM8.35656 9.16797H11.6382C11.518 7.19347 10.9574 5.27091 9.9974 3.5413C9.03713 5.27084 8.47619 7.1934 8.35573 9.16797H8.35656ZM11.9057 3.61214C12.7279 5.35604 13.2042 7.24279 13.3082 9.16797H16.6124C16.4502 7.88555 15.9191 6.67783 15.0836 5.69155C14.248 4.70526 13.1441 3.98287 11.9057 3.61214Z"
                  />
              </g>
              <defs>
                <clipPath id="clip0_2015_3596">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {{ $t('lang') }}
          </button>
        </div>
      </div>
    </div>
  </header>

</template>

<script>


export default {
  methods: {
    switchLanguage() {
      const newLang = this.$i18n.locale === 'en' ? 'ru' : 'en';
      this.$i18n.locale = newLang;
    },
  },
};


// menu smooth-scroll start
document.querySelectorAll('.menu-link').forEach(link => {
	link.addEventListener('click', function (e) {
		e.preventDefault(); // Отменяем стандартное поведение ссылки

		const targetId = this.getAttribute('href').substring(1); // Получаем ID цели
		const targetElement = document.getElementById(targetId); // Находим элемент по ID

		if (targetElement) {
			// Рассчитываем точное положение с учетом отступа
			const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 100; // Отступ 100px

			// Прокручиваем страницу к рассчитанной позиции
			window.scrollTo({
				top: targetPosition,
				behavior: 'smooth' // Плавная прокрутка
			});
		} 
	});
});
// menu smooth-scroll end





document.addEventListener("DOMContentLoaded", () => {
  const menuBtn = document.querySelector(".menu-btn");
  const menu = document.querySelector(".menu");
  const backdrop = document.querySelector(".backdrop");
  const body = document.body;

  // Переключение состояния меню
  const toggleMenu = () => {
    menuBtn?.classList.toggle("open");
    menu?.classList.toggle("d-flex");
    backdrop?.classList.toggle("d-none");
    body.classList.toggle("overflow-hidden");
  };

  // Смена языка
  const switchLanguage = () => {
    console.log("Language switched"); // Замените на вашу логику смены языка
    toggleMenu();
  };

  // Назначение обработчиков на элементы меню
  const applyMenuLinkHandlers = () => {
    const menuLinks = document.querySelectorAll(".menu-link");
    const langBtn = document.querySelector(".lang-btn");

    // Назначение обработчиков для пунктов меню
    menuLinks.forEach((link) => {
      link.removeEventListener("click", toggleMenu); // Удаляем старые обработчики
      link.addEventListener("click", toggleMenu);
    });

    // Назначение обработчика для кнопки смены языка
    if (langBtn) {
      langBtn.removeEventListener("click", switchLanguage); // Удаляем старый обработчик
      langBtn.addEventListener("click", switchLanguage);
    }
  };

  // Первичная инициализация
  const init = () => {
    if (menuBtn) menuBtn.addEventListener("click", toggleMenu);
    if (backdrop) backdrop.addEventListener("click", toggleMenu);
    applyMenuLinkHandlers();
  };

  // Переключение обработчиков при изменении размера окна
  const handleResize = () => {
  if (window.innerWidth >= 1200) {
    // Удаляем обработчики для desktop версии
    const menuLinks = document.querySelectorAll(".menu-link");
    const langBtn = document.querySelector(".lang-btn");

    // Переназначаем обработчики для menu-links
    menuLinks.forEach((link) => {
      link.removeEventListener("click", toggleMenu); // Убираем старые обработчики
      link.addEventListener("click", toggleMenu); // Назначаем новые обработчики
    });

    // Для кнопки смены языка
    if (langBtn) {
      langBtn.removeEventListener("click", switchLanguage); // Убираем старый обработчик
      langBtn.addEventListener("click", switchLanguage); // Назначаем новый обработчик
    }

  } else {
    // Включаем обработчики для мобильной версии
    applyMenuLinkHandlers();
  }
};


  // Инициализация обработчиков
  init();
  window.addEventListener("resize", handleResize);
});








</script>














<style lang="scss" scoped>
header {
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid var(--blue-stroke);
  background: var(--blue);
  color: var(--white);

  .container {
    position: unset;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    padding: 20px 26px 10px;
    text-align: center;

    @media (min-width: 768px) {
      padding: 19px 0 12px;
    }

    @media (min-width: 1200px) {
      padding: 12px 0 10px;
    }

    @media (min-width: 1680px) {
      padding: 16px 0 10px;
    }
  }

  .menu {
    display: none;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    overflow: auto;
    position: absolute;
    top: 66px;
    right: 0;
    width: 100%;
    height: calc(100dvh - 66px - 32*2px);
    background-color: var(--blue);
    z-index: 998;
    padding: 32px 0;

    @media (min-width: 414px) {
      width: 414px;
    }

    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: unset;
      height: unset;
      flex-direction: row;
      padding: 0;
    }
  }

  .menu-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 8px;

    @media (min-width: 1200px) {
      display: none;
    }

    .line {
      width: 100%;
      height: 2px;
      background-color: var(--green);
      border-radius: 2px;
      transition: .3s;
    }

    &.open {
      .line:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
        background-color: var(--white);
      }

      .line:nth-child(2) {
        opacity: 0;
        background-color: var(--white);
      }

      .line:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
        background-color: var(--white);
      }
    }
  }

  nav {
    margin: 0 auto;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 14px;

      @media (min-width: 1200px) {
        flex-direction: row;
        gap: 16px;
      }

      li {
        padding: 12px;

        a {
          font-family: 'Tektur';
          font-size: 20px;
          text-transform: uppercase;
          line-height: 110%;

          @media (min-width: 1200px) {
            font-size: 16px;
          }

        }
      }
    }
  }

  .lang-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-family: 'Tektur';
    text-transform: uppercase;
    padding: 12px;
    font-size: 16px;
    transition: .3s;

    svg {
      fill: var(--white);
      transition: .3s;
    }

    &:hover {
      color: var(--green-light);

      svg {
        fill: var(--green-light)
      }
    }
  }
}
</style>