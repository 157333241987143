<template>
  <HeaderNav />

  <main>
    <SectionH />
    <SectionPLV />
    <SectionSSV />
    <SectionESU />
    <SectionULU />
    <SectionLCS />
    <SectionC />
    <SectionF />
    <SectionP />
    <SectionPU />
    <SectionHP />
    <SectionLC />
    <SectionGE />
    <SectionL />
    <SectionPS />
    <SectionB />
  </main>

  <div class="backdrop d-none"></div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';
import SectionH from '@/components/SectionH.vue';
import SectionPLV from '@/components/SectionPLV.vue';
import SectionSSV from '@/components/SectionSSV.vue';
import SectionESU from '@/components/SectionESU.vue';
import SectionULU from '@/components/SectionULU.vue';
import SectionLCS from '@/components/SectionLCS.vue';
import SectionC from '@/components/SectionC.vue';
import SectionF from '@/components/SectionF.vue';
import SectionP from '@/components/SectionP.vue';
import SectionPU from '@/components/SectionPU.vue';
import SectionHP from '@/components/SectionHP.vue';
import SectionLC from '@/components/SectionLC.vue';
import SectionGE from '@/components/SectionGE.vue';
import SectionL from '@/components/SectionL.vue';
import SectionPS from '@/components/SectionPS.vue';
import SectionB from '@/components/SectionB.vue';

export default {
  name: 'App',
  components: {
    HeaderNav,
    SectionH,
    SectionPLV,
    SectionSSV,
    SectionESU,
    SectionULU,
    SectionLCS,
    SectionC,
    SectionF,
    SectionP,
    SectionPU,
    SectionHP,
    SectionLC,
    SectionGE,
    SectionL,
    SectionPS,
    SectionB
  }
}
</script>