<template>
  <section class="primary-logo-version">
    <div class="container container-btn">
      <div class="heading-wrap">
        <h2 id="logo">{{ $t('primary-logo-version.title') }}</h2>
        <button class="btn btn-btn" @click="downloadFile">{{ $t('primary-logo-version.btn') }}</button>
      </div>
      <div class="desc" v-html="$t('primary-logo-version.desc')"></div>
      <div class="items">
        <div class="item item--blue">
          <img src="@/img/primary-logo-green.svg" alt="">
          <div class="text">
            <div>#082b53</div>
            <div>RGB: 8, 43, 83</div>
          </div>
        </div>
        <div class="item item--green">
          <img src="@/img/primary-logo-white.svg" alt="">
          <div class="text">
            <div>#34cc67</div>
            <div>RGB: 52, 204, 103</div>
          </div>
        </div>
        <div class="item item--white">
          <img src="@/img/primary-logo-green.svg" alt="">
          <div class="text">
            <div>#ffffff</div>
            <div>RGB: 255, 255, 255</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_logo_main.zip";
      link.download = "LILBET_logo_main.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-logo-version {
  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 24px;

    @media (min-width: 768px) {
      padding: 40px 0;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid var(--pink);
    }

    @media (min-width: 1200px) {
      padding: 60px 0;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 38px 24px 32px;

    @media (min-width: 768px) {
      align-items: center;
      padding: 80px 18px 73px !important;
    }

    @media (min-width: 1020px) {
      padding: 36px 20px !important;
    }

    @media (min-width: 1200px) {
      padding: 76px 20px !important;
    }

    @media (min-width: 1680px) {
      padding: 96px 20px !important;
    }

    img {
      width: 120px;

      @media (min-width: 768px) {
        width: 168px;
      }
    }
  }

  .item--blue {
    background-color: var(--blue);
    color: var(--white);

    .text {
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 24px;
      }
    }
  }

  .item--green {
    background-color: var(--green);
    color: var(--blue);

    .text {
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 24px;
      }
    }
  }

  .item--white {
    grid-column: 1 / -1;
    background-color: var(--white);
    color: var(--blue);
    border-left: 1px solid var(--blue-light);
    border-right: 1px solid var(--blue-light);
    border-bottom: 1px solid var(--blue-light);
    align-items: center;
    padding-top: 48px;
    padding-bottom: 44px;

    @media (min-width: 768px) {
      border-top: 1px solid var(--blue-light);
      grid-column: unset;
    }

    .text {
      flex-direction: row;
      gap: 24px;
    }
  }

  .text {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 14px;
    letter-spacing: 0.14px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 24px;
    }
  }
}
</style>