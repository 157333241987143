<template>
  <section class="pattern-usage">
    <div class="container">
      <h2>{{ $t('pattern-usage.title') }}</h2>
      <div class="desc" v-html="$t('pattern-usage.desc')"></div>
    </div>
    <swiper :slidesPerView="'auto'" :breakpoints="breakpoints" :spaceBetween="2" class="mySwiper">
      <swiper-slide>
        <img src="@/img/patternts-usage-01.svg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/img/patternts-usage-02.svg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/img/patternts-usage-03.svg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/img/patternts-usage-04.svg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/img/patternts-usage-05.svg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/img/patternts-usage-06.svg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/img/patternts-usage-07.svg" alt="">
      </swiper-slide>
      <swiper-slide>
        <img src="@/img/patternts-usage-08.svg" alt="">
      </swiper-slide>
    </swiper>
    <div class="container container-btn">
      <button class="btn btn-btn d-nosm-none" @click="downloadFile">{{ $t('patterns.btn') }}</button>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/navigation';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // Адаптивные настройки для слайдера
      breakpoints: {
        768: { slidesPerView: 8 },
        1440: { slidesPerView: 8,
          spaceBetween: 4
         },
      }
    };
  },
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_pattern.zip";
      link.download = "LILBET_pattern.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.pattern-usage {
  .swiper {
    padding-top: 24px;

    @media (min-width: 768px) {
      padding-top: 40px;
    }

    @media (min-width: 1200px) {
      padding-top: 60px;
      margin-bottom: -7px;
    }
  }

  .swiper-wrapper>*:first-child {
    padding-left: 16px;

    @media (min-width: 768px) {
      padding-left: 0;
    }
  }

  .swiper-slide {
    overflow: hidden;
    height: 292px;
    width: 118px;

    @media (min-width: 768px) {
      height: 255px;
      width: unset;
    }

    @media (min-width: 1020px) {
      height: 332px;
    }

    @media (min-width: 1200px) {
      height: 546px;
    }

    img {
      transition: .5s;
      width: 100%;
      // width: inherit;

      // @media (min-width: 1921px) {
      //   width: inherit;
      // }

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .swiper-wrapper .swiper-slide:first-child {
    padding-left: 16px;

    @media (min-width: 768px) {
      padding: unset;
    }
  }

  .swiper-wrapper .swiper-slide:last-child {
    margin-right: 16px;

    @media (min-width: 768px) {
      margin: unset;
    }
  }
}
</style>