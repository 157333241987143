<template>
  <section class="graphic-elements section-colored bg-blue text-white">
    <div class="container container-btn">
      <div class="content">
        <h2 id="graphics">{{ $t('graphic-elements.title') }}</h2>
        <button class="btn btn-btn" @click="downloadFile">{{ $t('graphic-elements.btn') }}</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_icons.zip";
      link.download = "LILBET_icons.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.graphic-elements {
  padding: 80px 0 100px;
  background-image: url('@/img/graphic-elements-decor-414.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    padding: 68px 0;
    background-image: url('@/img/graphic-elements-decor-768.svg');
  }

  @media (min-width: 1020px) {
    background-image: url('@/img/graphic-elements-decor-1024.svg');
  }

  @media (min-width: 1200px) {
    padding: 75px 0;
    background-image: url('@/img/graphic-elements-decor-1440.svg');
  }

  @media (min-width: 1680px) {
    background-image: url('@/img/graphic-elements-decor-1920.svg');
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 60px;

    @media (min-width: 768px) {
      flex-direction: unset;
      gap: 40px;
    }

    @media (min-width: 1200px) {
      gap: 62px;
      padding-right: 28px;
    }

    @media (min-width: 1680px) {
      padding-right: 72px;
    }
  }
}
</style>