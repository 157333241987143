<template>
  <section class="example-of-symbol-usage">
    <div class="container">
      <h2>{{ $t('example-of-symbol-usage.title') }}</h2>
      <swiper :slidesPerView="'auto'" :breakpoints="breakpoints" :spaceBetween="16">
        <swiper-slide>
          <div class="swiper-slide-content">
            <img src="@/img/example-of-symbol-usage-image-01.webp" alt="" class="swiper-slide-img">
            <div class="swiper-slide-bg swiper-slide-bg--green"></div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-content">
            <img src="@/img/example-of-symbol-usage-image-02.webp" alt="" class="swiper-slide-img">
            <div class="swiper-slide-bg swiper-slide-bg--blue"></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const breakpoints = {
      768: {
        slidesPerView: 2,
        spaceBetween: 16
      },
      1020: {
        slidesPerView: 2,
        spaceBetween: 32
      },
    };

    return {
      breakpoints,
    };
  }
};
</script>


<style lang="scss" scoped>
.example-of-symbol-usage {
  .swiper {
    margin-top: 40px;

    @media (min-width: 1200px) {
      margin-top: 60px;
    }
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-slide-content {
    position: relative;
    display: flex;
    justify-content: center;
    width: 310px;

    @media (min-width: 768px) {
      width: 100%;
    }
  }

  .swiper-slide-img {
    position: relative;
    z-index: 1;
    text-align: center;
    width: 239px;

    @media (min-width: 768px) {
      width: 259px;
    }

    @media (min-width: 1020px) {
      width: 346px;
    }

    @media (min-width: 1200px) {
      width: 423px;
    }

    @media (min-width: 1680px) {
      width: 500px;
    }
  }

  .swiper-slide-bg {
    position: absolute;
    bottom: 0;
    width: 310px;
    height: 153px;
    border-radius: 24px;

    @media (min-width: 768px) {
      height: 167px;
      width: 100%;
    }

    @media (min-width: 1020px) {
      height: 221px;
    }

    @media (min-width: 1200px) {
      height: 270px;
    }

    @media (min-width: 1680px) {
      height: 320px;
    }
  }

  .swiper-slide-bg--green {
    background-color: var(--green);
  }

  .swiper-slide-bg--blue {
    background-color: var(--blue);
  }
}
</style>