<template>
  <section class="colors">
    <div class="container">
      <h2 id="colors">{{ $t('colors.title') }}</h2>
      <div class="desc" v-html="$t('colors.desc')"></div>
      <div class="items">
        <div class="item item--blue">
          <div class="item-text">
            #082b53
            <br>
            RGB: 8, 43, 83
            <br>
            CMYK: 100, 84, 24, 45
            <br>
            Pantone: 3591 C
          </div>
        </div>
        <div class="item item--white">
          <div class="item-text">
            #ffffff
            <br>
            RGB: 255, 255, 255
            <br>
            CMYK: 0, 0, 0, 0
            <br>
            Pantone: White
          </div>
        </div>
        <div class="item item--green">
          <div class="item-text">
            #34сс67
            <br>
            RGB: 52, 204, 103
            <br>
            CMYK: 65, 0, 74, 0
            <br>
            Pantone: 7481 C
          </div>
        </div>
        <div class="item item--pink">
          <div class="item-text">
            #ff00ff
            <br>
            RGB: 255, 0, 255
            <br>
            CMYK: 24, 79, 0, 0
            <br>
            Pantone: 2385 C
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.colors {
  .items {
    display: grid;
    gap: 8px;
    padding-top: 24px;

    @media(min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      padding-top: 40px;
      gap: 16px;
    }

    @media(min-width: 1020px) {
      gap: 20px;
    }

    @media(min-width: 1200px) {
      padding-top: 60px;
      gap: 24px;
    }

    @media(min-width: 1680px) {
      gap: 32px;
    }
  }

  .item {
    border-radius: 16px;
    font-size: 16px;
    letter-spacing: 0.16px;
    padding: 16px 20px 20px;

    @media (min-width: 768px) {
      padding: 40px;
      border-radius: 24px;
    }

    @media (min-width: 1020px) {
      padding: 24px 40px;
    }

    @media (min-width: 1200px) {
      padding: 40px;
    }

    .item-text {
      @media (min-width: 768px) {
        padding-top: 48px;
      }
    }
  }

  .item--blue {
    background-color: var(--blue);
    color: var(--white);
  }

  .item--white {
    background-color: var(--white);
    border: 1px solid var(--blue-light);
  }

  .item--green {
    background-color: var(--green);
  }

  .item--pink {
    background-color: var(--pink);
    color: var(--white);
  }
}
</style>