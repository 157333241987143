<template>
  <section class="hero section-colored bg-blue text-white">
    <div class="container">
      <div class="content">
        <div class="hero-main">
          <h1>{{ $t('hero.title') }}</h1>
          <div class="desc" v-html="$t('hero.desc')"></div>
          <button class="btn" @click="downloadFile">{{ $t('hero.btn') }}</button>
        </div>
        <div class="hero-logo">
          <img src="@/img/hero-logo.svg" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href = "/downloads/LILBET_all.zip";
      link.download = "LILBET_all.zip";
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url('@/img/hero-decor-414.svg');
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 108px;
  min-height: 100vh;
  box-sizing: border-box;

  @media (min-width: 768px) {
    background-image: url('@/img/hero-decor-768.svg');
    padding-top: 260px;
  }

  @media (min-width: 1020px) {
    background-image: url('@/img/hero-decor-1024.svg');
    padding-top: 100px;
    min-height: unset;
    height: 540px;
  }

  @media (min-width: 1200px) {
    background-image: url('@/img/hero-decor-1440.svg');
    padding-top: 121px;
  }

  @media (min-width: 1680px) {
    background-image: url('@/img/hero-decor-1920.svg');
    padding-top: 124px;
  }


  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    gap: 34px;
    text-align: center;

    @media (min-width: 414px) {
      max-width: 512px;
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      gap: 52px;
    }

    @media (min-width: 1020px) {
      gap: 26px;
      flex-direction: row;
      text-align: unset;
      max-width: unset;
    }

    @media (min-width: 1200px) {
      gap: 40px;
    }
  }

  .hero-main {
    flex: 1;
  }

  .hero-logo {
    flex: 1;
    text-align: end;
  }

  h1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 100%;
  }

  .btn {
    margin-top: 22px;
    width: 166px;

    @media (min-width: 768px) {
      margin-top: 60px;
      width: 150px;
    }

    @media (min-width: 1020px) {
      margin-top: 40px;
    }

    @media (min-width: 1200px) {
      margin-top: 23px;
      width: 192px;
    }
  }

  img {
    width: 220px;

    @media (min-width: 768px) {
      width: 340px;
    }

    @media (min-width: 1200px) {
      width: 220px;
    }
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 58px;
    }

    img {
      width: 358px;
    }
  }

  @media (min-width: 1680px) {
    img {
      width: 512px;
    }
  }
}
</style>