<template>
  <section class="unacceptable-logo-usage">
    <div class="container">
      <h2>{{ $t('unacceptable-logo-usage.title') }}</h2>

      <div v-if="isMobile">
        <swiper :slidesPerView="'auto'" :spaceBetween="16" :pagination="{
          clickable: true,
        }" :modules="modules">
          <swiper-slide>
            <div class="swiper-slide-content">
              <img src="@/img/unacceptable-logo-usage-image-01.svg" alt="" class="img">
              <div class="swiper-slide-info">
                <img src="@/img/icon-close.svg" alt="">
                <div>{{ $t('unacceptable-logo-usage.items.item1') }}</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-slide-content">
              <img src="@/img/unacceptable-logo-usage-image-02.svg" alt="" class="img">
              <div class="swiper-slide-info">
                <img src="@/img/icon-close.svg" alt="">
                <div>{{ $t('unacceptable-logo-usage.items.item2') }}</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-slide-content">
              <img src="@/img/unacceptable-logo-usage-image-03.svg" alt="" class="img">
              <div class="swiper-slide-info">
                <img src="@/img/icon-close.svg" alt="">
                <div>{{ $t('unacceptable-logo-usage.items.item3') }}</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-slide-content">
              <img src="@/img/unacceptable-logo-usage-image-04.svg" alt="" class="img">
              <div class="swiper-slide-info">
                <img src="@/img/icon-close.svg" alt="">
                <div>{{ $t('unacceptable-logo-usage.items.item4') }}</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-slide-content">
              <img src="@/img/unacceptable-logo-usage-image-05.svg" alt="" class="img">
              <div class="swiper-slide-info">
                <img src="@/img/icon-close.svg" alt="">
                <div>{{ $t('unacceptable-logo-usage.items.item5') }}</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-slide-content">
              <img src="@/img/unacceptable-logo-usage-image-06.svg" alt="" class="img">
              <div class="swiper-slide-info">
                <img src="@/img/icon-close.svg" alt="">
                <div>{{ $t('unacceptable-logo-usage.items.item6') }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div v-else>
        <div class="items">
          <div class="item">
            <img src="@/img/unacceptable-logo-usage-image-01.svg" alt="" class="img">
            <div class="item__info">
              <img src="@/img/icon-close.svg" alt="">
              <div>{{ $t('unacceptable-logo-usage.items.item1') }}</div>
            </div>
          </div>
          <div class="item">
            <img src="@/img/unacceptable-logo-usage-image-02.svg" alt="" class="img">
            <div class="item__info">
              <img src="@/img/icon-close.svg" alt="">
              <div>{{ $t('unacceptable-logo-usage.items.item2') }}</div>
            </div>
          </div>
          <div class="item">
            <img src="@/img/unacceptable-logo-usage-image-03.svg" alt="" class="img">
            <div class="item__info">
              <img src="@/img/icon-close.svg" alt="">
              <div>{{ $t('unacceptable-logo-usage.items.item3') }}</div>
            </div>
          </div>
          <div class="item">
            <img src="@/img/unacceptable-logo-usage-image-04.svg" alt="" class="img">
            <div class="item__info">
              <img src="@/img/icon-close.svg" alt="">
              <div>{{ $t('unacceptable-logo-usage.items.item4') }}</div>
            </div>
          </div>
          <div class="item">
            <img src="@/img/unacceptable-logo-usage-image-05.svg" alt="" class="img">
            <div class="item__info">
              <img src="@/img/icon-close.svg" alt="">
              <div>{{ $t('unacceptable-logo-usage.items.item5') }}</div>
            </div>
          </div>
          <div class="item">
            <img src="@/img/unacceptable-logo-usage-image-06.svg" alt="" class="img">
            <div class="item__info">
              <img src="@/img/icon-close.svg" alt="">
              <div>{{ $t('unacceptable-logo-usage.items.item6') }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { Pagination } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const isMobile = ref(window.innerWidth <= 767);
    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 767;
    };

    onMounted(() => {
      window.addEventListener('resize', updateIsMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateIsMobile);
    });

    return {
      modules: [Pagination],
      isMobile,
    };
  },
};
</script>

<style lang="scss">
.unacceptable-logo-usage {
  .items {
    margin-top: 40px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      display: grid;
      gap: 24px 16px;
    }

    @media (min-width: 1020px) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 60px;
    }

    @media (min-width: 1200px) {
      gap: 48px 28px;
    }

    @media (min-width: 1680px) {
      gap: 62px 34px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 336px;

    @media (min-width: 768px) {
      width: unset;
      gap: 22px;
    }
  }

  .item__info {
    display: flex;
    align-items: center;
    gap: 20px;
    letter-spacing: 0.18px;

    @media (min-width: 768px) {
      width: unset;
      gap: 16px;
    }
  }

  .swiper {
    margin-top: 40px;
    margin-bottom: -8px;

    @media (min-width: 1200px) {
      margin-top: 60px;
      margin-bottom: 0;
    }
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-slide-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80dvw;

    @media (min-width: 414px) {
      width: 336px;
    }

    @media (min-width: 768px) {
      width: unset;
    }
  }

  .swiper-slide-info {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (min-width: 768px) {
      gap: 20px;
    }
  }

  .swiper-pagination {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-top: 48px;
    width: auto !important;

    @media (min-width: 768px) {
      display: none;
    }

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 4px;
      transform: scale(0.75);
    }

    .swiper-pagination-bullet-active {
      background-color: var(--blue);
      transform: scale(1);
    }

    .swiper-pagination-bullet-active-next {
      transform: scale(0.75);
    }

    .swiper-pagination-bullet-active-next-next {
      transform: scale(0.75);
    }
  }
}
</style>