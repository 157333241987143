<template>
  <section class="logo-clear-space section-colored bg-blue text-white">
    <div class="container">
      <div class="content">
        <div>
          <h2>{{ $t('logo-clear-space.title') }}</h2>
          <div class="img-wrap-m">
            <img src="@/img/logo-clear-space-image.svg" alt="">
          </div>
          <div class="desc" v-html="$t('logo-clear-space.desc')"></div>
        </div>
        <div class="img-wrap-d">
          <img src="@/img/logo-clear-space-image.svg" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.logo-clear-space {
  background-image: url('@/img/logo-clear-space-decor-414.svg');
  background-repeat: no-repeat;
  padding: 60px 0 64px;

  @media (min-width: 768px) {
    padding: 80px 0;
    background-image: url('@/img/logo-clear-space-decor-768.svg');
  }

  @media (min-width: 1020px) {
    background-image: url('@/img/logo-clear-space-decor-1024.svg');
  }

  @media (min-width: 1200px) {
    padding: 100px 0 80px;
    background-image: url('@/img/logo-clear-space-decor-1440.svg');
  }

  @media (min-width: 1680px) {
    background-image: url('@/img/logo-clear-space-decor-1920.svg');
    padding: 100px 0;
  }

  h2 {
    @media (min-width: 768px) {
      height: 78px;
    }

    @media (min-width: 1020px) {
      height: unset;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    gap: 40px;
    margin-bottom: -6px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1020px) {
      gap: 100px;
    }

    @media (min-width: 1200px) {
      gap: 142px;
    }

    @media (min-width: 1680px) {
      gap: 180px;
    }
  }

  .desc {
    height: 230px;

    @media (min-width: 768px) {
      height: 260px;
    }

    @media (min-width: 1020px) {
      height: unset;
    }
  }

  img {
    width: 344px;

    @media (min-width: 768px) {
      width: 288px;
    }

    @media (min-width: 1020px) {
      width: 294px;
    }

    @media (min-width: 1200px) {
      width: 430px;
    }

    @media (min-width: 1680px) {
      width: 512px;
    }
  }

  .img-wrap-m {
    display: block;
    padding-top: 40px;
    padding-bottom: 12px;
    text-align: center;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .img-wrap-d {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
}
</style>